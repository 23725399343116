<template>
    <div class="col-sm-12 mt-4">
        <ValidationObserver v-slot="{ passes }">
            <form class="form-auth-small" @submit.prevent="passes(sendByMobileMoney)" >
                <div class="form-group" :class="{'errors': mobileMoney.mobileInputError == false,'success':mobileMoney.mobileNumber && mobileMoney.mobileInputError}">
                    <div class="float-label">
                        <label for="primary_number" class="control-label">Mobile Number*</label>
                        <vue-tel-input
                            v-model="mobileMoney.mobileNumber"
                            v-bind="primaryMobileNumber"
                            @validate ="mobileInputValidate"
                            ref="telInput"
                            @keypress.native="onlyNumber($event)">
                            <template v-slot:arrow-icon>
                                <span class="vti__dropdown-arrow">{{ '▼' }}</span>
                            </template>
                        </vue-tel-input>
                        <div class="line"></div>
                        <i class="fa fa-times" v-if="mobileMoney.mobileInputError == false"></i>
                        <i class="fa fa-check" v-if="mobileMoney.mobileInputError && mobileMoney.mobileNumber"></i>
                    </div>
                </div>
                <ValidationProvider name="select_country" rules="required" v-slot="{ errors}">
                    <div class="country-dropdown">
                        <div class="form-group form-select single-multiselect" :class="{'errors': errors[0],'success':mobileMoney.mobileNetwork && mobileMoney.mobileNetwork.length != 0  && !errors[0]}">
                            <span class="float-label">
                            <label for="Country">Mobile Money Network*</label>
                                <multiselect
                                    placeholder=""
                                    v-model="mobileMoney.mobileNetwork"  
                                    :show-labels="false" 
                                    :options="mobileNetworkOptions" 
                                    :searchable="true">
                                </multiselect> 
                                <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                <i class="fa fa-check" v-if="mobileMoney.mobileNetwork && mobileMoney.mobileNetwork.length != 0 && !errors[0]"></i>
                            </span>
                        </div>
                    </div>
                </ValidationProvider>
                <div class="phone-with-country d-flex justify-content-between align-items-center disabled">
                    <div class="select-country">
                        <div class="form-group form-select single-multiselect">
                            <span class="float-label">
                                <label for="amount">Amount</label>
                                <multiselect
                                    placeholder=""
                                    v-model="mobileMoney.currency"   
                                    :show-labels="false" 
                                    :options="currencyOptions"
                                    :searchable="false"
                                    label="name" 
                                    track-by="name">
                                    <!-- disabled -->
                                    <template slot="singleLabel" slot-scope="props">
                                        <span class="mr-2" :class="props.option.flagClass"></span>
                                        <span class="option-title">
                                            <span>{{ props.option.name }}</span>
                                        </span>
                                    </template>
                                    <template slot="option" slot-scope="props">
                                        <div class="option-title">
                                            <span class="mr-2" :class="props.option.flagClass"></span>
                                            <span>{{ props.option.name }}</span>
                                        </div>
                                    </template>
                                </multiselect> 
                            </span>
                        </div>
                    </div>
                    <div class="add-number">
                        <div class="form-group">
                            <span class="float-label">
                                <input type="text" v-model="mobileMoney.amount" class="form-control" id="amount" placeholder="0.00" @keypress="onlyNumber($event)">
                                <!-- disabled -->
                                <div class="line"></div>
                            </span>
                        </div>
                    </div>
                </div>
                <button type="submit" class="btn btn-gradient btn-lg btn-mw btn-block">Pay by Mobile Money</button>
            </form>
        </ValidationObserver>
    </div>
</template>
<script>
const { VueTelInput } = require('vue-tel-input')
const { ValidationObserver,ValidationProvider } = require("vee-validate")
export default {
    name:'MobileMoney',
    components: {
        ValidationObserver,
        ValidationProvider,
        VueTelInput,
        Multiselect: () => import('vue-multiselect'),
    },data(){
        return{
            mobileMoney: {
                mobileNumber:'',
                mobileNetwork:'',
                mobileInputError:'',
                currency:{ name: 'USD', flagClass: "flag-icon flag-icon-us fi-md fi-round"},
                amount:500,
            },
           

            primaryMobileNumber:{
                mode: "auto",
                defaultCountry:"GB",
                autoFormat:false,
                dropdownOptions:{
                    showFlags: true,
                    showDialCodeInSelection:true
                }, 
            },

            mobileNetworkOptions:['MTN Prepaid Topup','Vodafone Prepaid Topup','Airtel-Tigo Prepaid Topup'],
            currencyOptions:[
                { name: 'GBP', flagClass: "flag-icon flag-icon-gb fi-md fi-round"},
                { name: 'GHS', flagClass: "flag-icon flag-icon-gh fi-md fi-round"},
                { name: 'USD', flagClass: "flag-icon flag-icon-us fi-md fi-round"},
                { name: 'RUB', flagClass:"flag-icon flag-icon-ru fi-md fi-round"},
            ],
        }
    },methods:{
        sendByMobileMoney(){
            if(typeof(this.mobileMoney.mobileInputError) == 'undefined'){
                this.mobileMoney.mobileInputError = false
            }
            if(this.mobileMoney.mobileInputError){
               this.$router.push({ path: '/payment/payment-transfer' })
            }
        
        },

        onlyNumber (event) {
            let keyCode = (event.keyCode ? event.keyCode : event.which);
            if (keyCode < 48 || keyCode > 57) { 
                event.preventDefault();
            }
            if (/^\W$/.test(event.key)) {
                event.preventDefault();
            }
        },

        mobileInputValidate(phoneObject){
            this.mobileMoney.mobileInputError = phoneObject.valid
        }
    }
}
</script>
<style scoped>
    @import '~vue-tel-input/dist/vue-tel-input.css';
</style>